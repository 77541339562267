<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="logo-container">
        <div class="logo rotating">
          <a href="/" target="_blank" rel="noopener noreferrer" aria-label="Sol">
            <img src="@/assets/AR/img7.png" alt="logo">
         </a>
        </div>
      </div>
      <p>2024 JCVLENV / / 🐝 / / ARD</p>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  padding: 1em 1em;
  background-color: #020202;
  color: #fef9e6;
  text-align: center;
  transition: background-color 0.3s ease;
}
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-bottom: 0.5em;
}
.footer-content p {
  font-size: 1em;
  font-family: "Chakra Petch";
  opacity: 75%;
}
.logo-container {
  display: flex;
  gap: 30px;
  flex-direction: row;
  padding: 1em;
  align-items: center;
}
.logo {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease, filter 0.3s ease;
  cursor: pointer;
}
.logo.rotating img:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .footer {
    padding: 1em;
  }
  .logo {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 480px) {
  .logo {
    width: 30px;
    height: 30px;
  }
}
</style>