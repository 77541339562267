<template>
  <div class="heartspores-container">
    <div class="overlay">
      <div class="text-content">
        <p class="mystic-text">
          El fin está cerca, susurrado por vientos que arrastran el espejismo de lo que creímos real.<br/>
          La tierra cruje bajo el peso de lo insaciable, mientras las sombras de lo perdido se alzan como fantasmas.<br/>
          En escenarios vacíos, actores desfasados siguen representando un guión obsoleto, olvidando que su obra ya no conmueve ni engaña.<br/>
          La oscuridad avanza, no como una amenaza, sino como una promesa que reclama su lugar en este acto final.<br/>
          <br/>
          La semilla persiste, enraizada en el profundo río de lo ancestral, donde el equilibrio entre lo sagrado y lo humano nunca fue olvidado.<br/>
          La muerte danza sobre el fuego, reclamando lo que se disuelve y dejando en sus pasos las cenizas del renacimiento.<br/>
          Veneramos a los guardianes de lo eterno, fundiéndonos con la luz de aquello que nunca morirá.<br/>
          El mundo vive en nosotras y nosotras en él: ha llegado el momento de despertar.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeartsporesCont',
};
</script>

<style scoped>

.heartspores-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-out;
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.text-content {
  text-align: center;
  max-width: 80%;
  color: #F1C27D;
  font-family: "Handjet";
  font-size: 5vw; 
  line-height: 1.6;
  text-shadow: 0px 0px 15px rgba(255, 255, 255, 0.7), 0px 0px 10px rgba(255, 255, 255, 0.3);
  animation: pulseText 5s infinite alternate;
  padding: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulseText {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

@media (max-width: 768px) {
  .text-content {
    font-size: 6vw; 
  }
}

@media (max-width: 480px) {
  .text-content {
    font-size: 8vw; 
  }
}
</style>