<template>
  <nav class="navbar" :class="{ scrolled: isScrolled }">
    <div class="nav-title">
      <router-link to="/">
        <h5>PORTFOLIO WEB</h5>
      </router-link>
    </div>
    <div class="iconos">
      <a href="https://www.linkedin.com/in/julietxcalderon/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <i class="bi bi-linkedin"></i>
      </a>
      <a href="https://www.behance.net/julietxcalderon/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <i class="bi bi-behance"></i>
      </a>
      <a href="https://github.com/julietxcalderon" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
        <i class="bi bi-github"></i>
      </a>
      <a href="https://www.instagram.com/julietxcalderon" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
        <i class="bi bi-instagram"></i>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isScrolled: false,
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.navbar {
  background-color: var(--color-base-claro);
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.navbar.scrolled {
  padding: 5px 20px;
  background-color: var(--color-base-claro);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}
.nav-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.nav-title h5 {
  text-align: left;
  font-family: "Playfair Display", serif;
  margin: 0;
  font-size: 1.2em;
  color: var(--color-texto);
  cursor: pointer;
  transition: color 0.3s ease;
}
.nav-title h5:hover {
  color: var(--color-boton-hover);
}
.iconos {
  display: flex;
  gap: 1.5em;
  margin-bottom: 0.5em;
  margin-right: 2em;
}
.iconos a {
  color: var(--color-texto);
  transition: color 0.3s ease, transform 0.3s ease;
}
.iconos a:hover {
  color: var(--color-boton-hover);
  transform: scale(1.1);
}
.iconos i {
  font-size: 1.5em;
}
@media (max-width: 768px) {
  .nav-title h5 {
    font-size: 1em;
  }
  .iconos {
    gap: 1em;
    margin-right: 2em;
  }
}
@media (max-width: 480px) {
  .nav-title h5 {
    font-size: 0.9em;
  }
  .iconos i {
    font-size: 1.3em;
  }
}
</style>